<template>
  <div class="video" ref="video"></div>
</template>

<script>
import { mapState } from "vuex";
// import SecurMeetExternalAPI from '@/assets/API.js'
export default {
  data() {
    return {
      api: null,
    };
  },
  computed: {
    ...mapState("user", ["firstName", "email", "meetServer"]),
    ...mapState('rooms', ['name'])
  },
  destroyed(){
    this.api.dispose();
  },
  mounted() {
    return;
    const options = {
      roomName: this.$route.params.id,
      parentNode: this.$refs.video,
    };
    window.meet = {};
    window.meet.api = new SecurMeetExternalAPI(this.meetServer, options);
    this.api = window.meet.api;

    window.meet.api.executeCommands({
      displayName: this.firstName,
      subject: this.name,
      email: this.email,
    });

    const onClose = () => {
      if (this.email) this.$router.push("/");
      else this.$router.push("/");     
    };

    window.meet.api.on("readyToClose", function (e) {
      window.meet.api.dispose();
      onClose();
    });
  },
};
</script>

<style scoped lang="less">
.video {
 flex-grow: 1;
}
</style>
