<template>
  <div class="meeting_page 100h vh100 d-flex d-col">
    <TopToolbar />
    <div class="main">
      <Sidebar />
      <Meetings />
    </div>
    <Footer />
  </div>
</template>

<script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import Sidebar from "@/components/Toolbars/Sidebar";
import Meetings from "@/components/Meeting/Meeting.vue";
import Footer from "@/components/Toolbars/Footer.vue";

export default {
  name: "Meeting",
  components: {
    Sidebar,
    TopToolbar,
    Meetings,
    Footer,
  },
};
</script>

<style lang="less" scoped>
.welcome {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  display: flex;
  flex-grow: 1;
}
</style>
